@import '../../../../resources';
.userSearch {
  background: #f7f9fe;
  width: 100%;
  max-width: 800px;
  border-radius: 7px;
  padding: 15px;

  @media screen and (max-height: 600px) {
    margin-top: 100px;
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #e1e1e1;
    padding-bottom: 10px;
    margin-bottom: 40px;
    p {
      font-size: 16px;
      @include Gilroy-Medium;
      color: #535353;
      border-left: 1px solid #535353;
      padding-left: 10px;
    }
    svg {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .form {
    .form_wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: 1px solid #c8c8c8;
      padding-bottom: 20px;
      .card {
        width: 32%;
        margin-bottom: 20px;
        @include mobile-800 {
          width: 48%;
        }
        input,
        select {
          @include Roboto;
          font-size: 14px;
          background: #f6f9fe;
          border: 1px solid #b4b4b4;
          border-radius: 4px;
          padding: 15px 25px;
          width: 100%;
          &::placeholder {
            color: #000;
          }
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 20px 0;
      button {
        margin-left: 20px;
        @include Gilroy-Regular;
        font-size: 14px;
        border: none;
        background: none;
        &.save {
          padding: 8px 16px;
          background: #ff8a00;
          border-radius: 21px;
          color: #fff;
        }
      }
    }
  }
}
