@import '../../../../resources';
.bulkResponse {
  padding: 0 50px;
  margin-bottom: 50px;
  @include mobile-800 {
    padding: 0 20px;
  }
  .stats {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #d3d3d3;
    padding-top: 40px;
    flex-wrap: wrap;
    .card {
      display: flex;
      width: 24%;
      align-items: center;
      background: #ffffff;
      cursor: pointer;
      border-radius: 10px;
      padding: 20px;
      @include mobile-800 {
        width: 47%;
        margin-bottom: 30px;
      }
      @include mobile-600 {
        width: 100%;
      }
      &:hover {
        border: 0.5px solid#ff8a00;
        box-shadow: 0px 18px 34px rgba(255, 138, 0, 0.07);
      }
      .icon {
        width: 40px;
        height: 40px;
        background: #ff8a00;
        box-shadow: 0px 8px 12px rgba(255, 138, 0, 0.36);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 10px;
        svg {
          color: #fff;
        }
      }
      .fig {
        h1 {
          @include Gilroy-Bold;
          font-size: 30px;
          margin-right: 8px;
        }
      }
      .tag {
        @include Gilroy-Medium;
        font-size: 13px;
        text-transform: uppercase;
      }
    }
  }
  .errors {
    padding-top: 30px;
    @include Roboto;
    h1 {
      font-size: 20px;
      padding-bottom: 10px;
      b {
        color: #ff8a00;
        text-transform: uppercase;
      }
    }
    h2 {
      font-size: 20px;
      padding-bottom: 10px;
      b {
        color: red;
      }
    }
    p {
      @include Roboto;
    }
    button {
      background-color: #ff8a00;
      padding: 10px;
      color: #fff;
      border: none;
      border-radius: 6px;
      @include Roboto;
      margin-bottom: 15px;
    }
  }
  .table {
    padding-top: 20px;
    overflow-x: auto;
    table {
      width: 100%;
      text-align: left;
      thead {
        tr {
          background-color: #dbe2ef;
          border-radius: 4px;
          th {
            padding: 10px;
            @include Roboto;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      tbody {
        tr {
          background-color: #fff;
          border-radius: 4px;
          cursor: pointer;
          td {
            padding: 10px;
            @include Gilroy-Regular;
            font-weight: 400;
            font-size: 14px;
          }
          &.blur {
            color: transparent;
            text-shadow: 0 0 8px #000;
            cursor: pointer;
          }
        }
      }
    }
  }
}
