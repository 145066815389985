@import '../../../../resources';
.taxPayer {
  padding: 0 50px;
  margin-bottom: 50px;
  position: relative;
  @include mobile-1024 {
    padding: 0 20px;
  }
  .showPDF {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    z-index: 20;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.726);
    @include mobile-1024 {
      margin: 0;
    }
    .cover {
      background-color: #fff;
      width: 100%;
      .file-cover {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        display: flex;
        justify-content: center;
      }
      .download {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        button {
          padding: 10px 20px;
          background: #ff8a00;
          border-radius: 21px;
          border: none;
          @include Gilroy-Regular;
          font-size: 14px;
          color: #fff;
        }
        svg {
          font-size: 30px;
          cursor: pointer;
        }
      }
      .pg-viewer-wrapper {
        width: 80%;
      }
      canvas {
        width: 100% !important;
      }
    }
  }
  .search_top {
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 15px 41px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 40px;
    .form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: #f7f9fe;
      border: 1px solid #c8c8c8;
      border-radius: 11px;
      padding: 10px 10px 10px 30px;
      input {
        width: 90%;
        border: none;
        border-left: 1px solid #535353;
        background: none;
        padding: 6px 10px;
        outline: none;
      }
      button {
        width: 5%;
        background: none;
        border: none;
        svg {
          font-size: 20px;
        }
      }
    }
  }
  .stats {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #d3d3d3;
    padding-top: 40px;
    flex-wrap: wrap;
    .card {
      display: flex;
      width: 24%;
      align-items: center;
      background: #ffffff;
      cursor: pointer;
      border-radius: 10px;
      padding: 20px;
      @include mobile-800 {
        width: 47%;
        margin-bottom: 30px;
      }
      @include mobile-600 {
        width: 100%;
      }
      &:hover {
        border: 0.5px solid#ff8a00;
        box-shadow: 0px 18px 34px rgba(255, 138, 0, 0.07);
      }
      .icon {
        width: 40px;
        height: 40px;
        background: #ff8a00;
        box-shadow: 0px 8px 12px rgba(255, 138, 0, 0.36);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 10px;
        svg {
          color: #fff;
        }
      }
      .fig {
        h1 {
          @include Gilroy-Bold;
          font-size: 30px;
          margin-right: 8px;
        }
      }
      .tag {
        @include Gilroy-Medium;
        font-size: 13px;
      }
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-top: 40px;
    h1 {
      @include Gilroy-Bold;
      font-size: 25px;
      @include mobile-600 {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    .add {
      button {
        padding: 10px 20px;
        background: #ff8a00;
        border-radius: 21px;
        border: none;
        @include Gilroy-Regular;
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .table {
    overflow-x: auto;
    table {
      width: 100%;
      text-align: left;
      thead {
        tr {
          background-color: #dbe2ef;
          border-radius: 4px;
          th {
            padding: 10px;
            @include Roboto;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      tbody {
        tr {
          background-color: #fff;
          border-radius: 4px;
          cursor: pointer;
          td {
            padding: 10px;
            @include Gilroy-Regular;
            font-weight: 400;
            font-size: 14px;
          }
          &.blur {
            color: transparent;
            text-shadow: 0 0 8px #000;
            cursor: pointer;
          }
        }
      }
    }
  }
}
