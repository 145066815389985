@import '../../resources';
.main_dashboard {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .main_body {
    display: flex;
    min-height: 100vh;
    .main_container {
      height: 100vh;
      min-height: 100vh;
      overflow-y: auto;
      width: 83%;
      padding-top: 100px;
      background: #f1f5fd;
      @include mobile-1024 {
        width: 100%;
      }
      .orangeButton {
        @include Gilroy-Regular;
        font-size: 14px;
        border: none;
        padding: 10px 30px;
        background: #ff8a00;
        border: 1px solid #ff8a00;
        border-radius: 21px;
        color: #fff;
        display: flex;
        p {
          padding-right: 5px;
        }
      }
    }
  }
  .modal {
    position: absolute;
    z-index: 20;
    height: 100vh;
    width: 100%;
    background: #00000082;
    display: none;
    justify-content: center;
    align-items: center;
    @media screen and (max-height: 576px) {
      align-items: unset;
    }
    &.hey {
      overflow-y: auto;
      align-items: unset;
      padding: 20px 0;
    }
    @include mobile-550 {
      overflow-y: auto;
      &.kil {
        align-items: unset;
      }
    }
    &.active {
      display: flex;
    }
  }
}
.loaders {
  background: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
