@import '../../../../resources';
.uploadData {
  padding: 0 50px;
  margin-bottom: 50px;
  @include mobile-800 {
    padding: 0 20px;
  }
  .top_button {
    text-align: right;
    button {
      @include Gilroy-Regular;
      padding: 12px 23px;
      background: #f1f5fd;
      border: 1px solid #afafaf;
      border-radius: 4px;
      font-size: 14px;
      margin-bottom: 40px;
    }
  }
  .upload_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    .left {
      display: flex;
      align-items: center;
      @include mobile-800 {
        padding-bottom: 20px;
      }
      h1 {
        @include Gilroy-Bold;
        font-size: 25px;
        margin-left: 15px;
      }
      svg {
        cursor: pointer;
      }
    }
    .right {
      display: flex;
      align-items: center;
      border: 0.5px solid #b4b4b4;
      border-radius: 5px;
      padding: 0px 25px;
      flex-wrap: wrap;
      .card {
        display: flex;
        margin-left: 10px;
        padding: 10px;
        cursor: pointer;

        p {
          @include Gilroy-Regular;
          padding-left: 10px;
          color: #535353;
          font-size: 14px;
        }
        svg {
          color: #535353;
        }
        &.active {
          p {
            color: #ff8a00;
          }
          svg {
            color: #ff8a00;
          }
        }
      }
      .line {
        height: 1px;
        width: 40px;
        margin-left: 10px;
        background-color: #b4b4b4;
      }
    }
  }
  .form {
    .form_card {
      width: 100%;
      margin-bottom: 30px;
      p {
        @include Gilroy-Regular;
        font-size: 14px;
        padding-bottom: 6px;
      }
      select,
      input {
        width: 100%;
        background: #e9f0fd;
        border: 0.5px solid #a7a7a7;
        border-radius: 3px;
        @include Gilroy-Regular;
        font-size: 16px;
        padding: 10px 20px;
      }
      .css-13cymwt-control {
        background: #e9f0fd;
        border: 0.5px solid #a7a7a7;
        @include Gilroy-Regular;

        .css-1fdsijx-ValueContainer {
          padding: 10px 20px;
        }
        .css-1jqq78o-placeholder {
          color: #000;
        }
      }
    }
    .image_card {
      width: 100%;
      background: #ecf2ff;
      border: 1px dashed #6a6a6a;
      border-radius: 9px;
      display: flex;
      justify-content: center;
      input {
        display: none;
      }
      label {
        width: 500px;
        text-align: center;
        padding: 50px;
        .label {
          color: #4b4b4b;
          word-break: break-all;
          @include Roboto;
          font-style: italic;
          font-size: 13px;
          line-height: 144.19%;
          padding-bottom: 10px;
          cursor: pointer;
        }
        .buttonf {
          width: 100%;
          display: flex;
          justify-content: center;
          div {
            @include Gilroy-Regular;
            font-size: 14px;
            border: none;
            padding: 10px 30px;
            background: #ff8a00;
            border: 1px solid #ff8a00;
            border-radius: 21px;
            color: #fff;
            display: flex;
            cursor: pointer;
            p {
              padding-right: 5px;
            }
          }
        }
      }
    }
    .submit {
      display: flex;
      justify-content: right;
      padding-top: 30px;
      button {
        @include Gilroy-Regular;
        font-size: 14px;
        border: none;
        padding: 10px 30px;
        background: #ff8a00;
        border: 1px solid #ff8a00;
        border-radius: 21px;
        color: #fff;
        display: flex;
        p {
          padding-right: 5px;
        }
      }
    }
  }
  .valid {
    .table {
      overflow-x: auto;
      table {
        width: 100%;
        text-align: left;
        thead {
          tr {
            background-color: #dbe2ef;
            border-radius: 4px;
            th {
              padding: 10px;
              @include Roboto;
              font-weight: 400;
              font-size: 16px;
            }
          }
        }
        tbody {
          tr {
            background-color: #fff;
            border-radius: 4px;
            cursor: pointer;
            td {
              padding: 10px;
              @include Gilroy-Regular;
              font-weight: 400;
              font-size: 14px;
              text-transform: capitalize;
            }
            &.blur {
              color: transparent;
              text-shadow: 0 0 8px #000;
              cursor: pointer;
            }
          }
        }
      }
    }
    .pagination {
      padding-top: 30px;
      display: flex;
      justify-content: right;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          @include Roboto;
          font-size: 12px;
          font-weight: bold;
          list-style: none;
          padding: 6px 10px;
          border: 1px solid #000;
          margin-right: 5px;
          cursor: pointer;
          &:hover {
            background-color: #fff;
            border: none;
            border: 1px solid #535353;
            color: #535353;
          }
          &.active {
            background-color: #fff;
            border: none;
            border: 1px solid #535353;
            color: #535353;
          }
        }
      }
    }
  }
}
