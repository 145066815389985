@import '../../../../resources';
.restrictionModal {
  background: #fff;
  width: 100%;
  max-width: 350px;
  border-radius: 7px;
  padding: 15px;
  .top {
    padding-bottom: 10px;
    margin-bottom: 40px;
    text-align: right;

    svg {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .center {
    text-align: center;
    img {
      width: 100px;
      margin-bottom: 25px;
    }
    h1 {
      font-size: 25px;
      @include Gilroy-Bold;
      padding-bottom: 10px;
    }
    p {
      @include Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 137.7%;
      padding-bottom: 30px;
    }
    button {
      @include Gilroy-Regular;
      font-size: 14px;
      border: none;
      background: none;
      padding: 8px 16px;
      background: #ff8a00;
      border-radius: 21px;
      color: #fff;
      margin-bottom: 50px;
    }
  }
}
