.loader-comp {
  width: 100%;
  text-align: center;
  &.right {
    text-align: right;
  }
  img {
    width: 100px;
  }
}
