@import '../../../../resources';
.newHome {
  margin-top: -100px;
  display: flex;
  height: 100vh;
  justify-content: center;
  padding: 0 10px;
  .cover {
    width: 100%;
    padding-top: 100px;
    .cards {
      display: flex;
      flex-wrap: wrap;
      gap: 2.5%;
      .card {
        background: #fff;
        width: 23%;
        margin-bottom: 30px;
        cursor: pointer;
        padding: 15px;
        @include Roboto;
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 100px;
        @include mobile-800 {
          width: 31%;
        }
        @include mobile-550 {
          width: 48%;
        }
        @include mobile-420 {
          width: 100%;
        }
        h1 {
          font-size: 25px;
          color: #6f7072;
          padding-bottom: 20px;
        }
        .cons {
          display: flex;
          align-items: center;
          svg {
            font-size: 30px;
          }
        }
      }
    }
    .search {
      text-align: center;
      input {
        width: 100%;
        height: 62px;
        background: #ebeff5;
        border: 0.5px solid #c2c2c2;
        border-radius: 7px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        padding: 0 20px;
      }
      .buttons {
        text-align: center;
        padding-top: 30px;
        button {
          background: #ff8a00;
          border-radius: 21px;
          padding: 10px 20px;
          color: #fff;
          border: none;
        }
      }
    }
  }
}
