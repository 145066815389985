@import '../../../../resources';
.indtransaction {
  padding: 0 50px;
  margin-bottom: 50px;
  position: relative;
  @include mobile-1024 {
    padding: 0 20px;
  }
  .top_button {
    text-align: right;
    button {
      @include Gilroy-Regular;
      padding: 12px 23px;
      background: #f1f5fd;
      border: 1px solid #afafaf;
      border-radius: 4px;
      font-size: 14px;
      margin-bottom: 40px;
    }
  }
  .back {
    .back_left {
      display: flex;
      align-items: center;
      @include mobile-800 {
        padding-bottom: 20px;
      }
      h1 {
        @include Gilroy-Bold;
        font-size: 25px;
        margin-left: 15px;
      }
      svg {
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
  .transactions_wrap {
    padding-top: 30px;
    .list1 {
      width: 100%;
      background-color: #fff;
      padding: 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-radius: 5px;
      .card,
      .cards {
        width: 30%;
        margin-bottom: 20px;
        @include mobile-600 {
          width: 100%;
        }
        p {
          @include Gilroy-Regular;
          font-size: 13px;
          padding-bottom: 7px;
        }
        button {
          background-color: #ff8a00;
          padding: 10px;
          color: #fff;
          border: none;
          border-radius: 6px;
        }
        h2 {
          @include Gilroy-Bold;
          font-size: 18px;
          overflow-wrap: break-word;
        }
      }
      .cards {
        width: 100%;
        p {
          padding-top: 7px;
        }
      }
      .long {
        width: 100%;
        margin-bottom: 20px;
        @include mobile-600 {
          width: 100%;
        }
        p {
          @include Gilroy-Regular;
          font-size: 13px;
          padding-bottom: 7px;
        }
        h2 {
          @include Gilroy-Bold;
          font-size: 18px;
          overflow-wrap: break-word;
        }
        h1 {
          @include Gilroy-Bold;
          font-size: 18px;
          width: 100%;
          border-bottom: 1px solid #afafaf;
          padding-bottom: 6px;
          margin-bottom: 15px;
        }
        .wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .card {
            width: 23%;
          }
          @include mobile-800 {
            width: 48%;
          }
          @include mobile-600 {
            width: 100%;
          }
        }
        table {
          width: 100%;
          text-align: left;
          thead {
            tr {
              background-color: #dbe2ef;
              border-radius: 4px;
              th {
                padding: 10px;
                @include Roboto;
                font-weight: 400;
                font-size: 16px;
              }
            }
          }
          tbody {
            tr {
              background-color: #fff;
              border-radius: 4px;
              cursor: pointer;
              td {
                padding: 10px;
                @include Gilroy-Regular;
                font-weight: 400;
                font-size: 14px;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
    .button-part {
      text-align: right;
      padding-top: 20px;
      button {
        padding: 10px 20px;
        background: #ff8a00;
        border-radius: 5px;
        border: none;
        @include Gilroy-Regular;
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .modals {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #00000042;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    .edit-modal {
      background-color: #fff;
      max-width: 600px;
      padding: 30px;
      overflow-y: auto;
      // max-height: ;
      .top {
        padding-bottom: 20px;
        h1 {
          @include Gilroy-Bold;
          font-size: 20px;
        }
      }
      .form {
        .dynamic {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .card {
            width: 46%;
            margin-bottom: 10px;
            p {
              @include Gilroy-Medium;
              padding-bottom: 5px;
              font-size: 13px;
            }
            input {
              width: 100%;
              padding: 10px;
            }
          }
        }
        .button-part {
          text-align: right;
          padding-top: 20px;
          button {
            padding: 10px 20px;
            background: #ff8a00;
            border-radius: 5px;
            border: none;
            @include Gilroy-Regular;
            font-size: 14px;
            color: #fff;
            &.cancel {
              background: none;
              color: #ff8a00;
              border: 1px solid #ff8a00;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
