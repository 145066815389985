@import '../../../../resources';
.dataModal {
  background-color: #fff;
  width: 100%;
  max-width: 600px;
  border-radius: 7px;
  padding: 15px;
  padding: 50px;
  .top {
    padding-bottom: 10px;
    text-align: right;
    svg {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
  @include mobile-420 {
    padding: 50px 20px;
  }
  .card {
    width: 35%;
    text-align: center;
    @include mobile-420 {
      width: 48%;
    }
    img {
      margin-bottom: 21px;
    }
    h2 {
      font-size: 20px;
      @include Gilroy-Medium;
      color: #282828;
      line-height: 104.3%;
      font-weight: 600;
      padding-bottom: 25px;
    }
    button {
      @include Gilroy-Regular;
      font-size: 14px;
      border: none;
      background: none;
      padding: 10px 30px;
      background: none;
      border: 1px solid #ff8a00;
      border-radius: 21px;
      color: #ff8a00;
      &:hover {
        background: #ff8a00;
        color: #fff;
      }
    }
  }
}
