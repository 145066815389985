@import '../../../../resources';
.searchResult {
  position: relative;
  .showPDF {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    z-index: 20;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.726);
    @include mobile-1024 {
      margin: 0;
    }
    .cover {
      background-color: #fff;
      width: 100%;
      .file-cover {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        display: flex;
        justify-content: center;
      }
      .download {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        button {
          padding: 10px 20px;
          background: #ff8a00;
          border-radius: 21px;
          border: none;
          @include Gilroy-Regular;
          font-size: 14px;
          color: #fff;
        }
        svg {
          font-size: 30px;
          cursor: pointer;
        }
      }
      .pg-viewer-wrapper {
        width: 80%;
      }
      canvas {
        width: 100% !important;
      }
    }
  }
  .top {
    padding-left: 40px;
    border-bottom: 0.5px solid #878787;
    .search {
      margin-bottom: 40px;
      background: #f1f5fd;
      border: 0.5px solid #484848;
      border-radius: 30.5px;
      width: 100%;
      max-width: 500px;
      display: flex;
      overflow: hidden;
      input {
        width: 100%;
        padding: 15px 20px;
        background: none;
        outline: none;
        border: none;
      }
      .search-icon {
        display: flex;
        align-items: center;
        padding: 0 20px;
        border: none;
        border-left: 1px solid #535353;
        cursor: pointer;
        svg {
          color: #484848;
        }
      }
    }
  }
  .loadx {
    text-align: center;
    img {
      width: 100px;
    }
  }
  .result-body {
    padding: 30px 40px;
    .top-b {
      padding-bottom: 21px;
      p {
        @include Gilroy-Regular;
        font-size: 16px;
        color: #8c8c8c;
        b {
          font-weight: 700;
          color: #000;
        }
      }
      select {
        width: 100%;
        height: 40px;
        margin-top: 20px;
        background: #ebeff5;
        border: 0.5px solid #c2c2c2;
        border-radius: 7px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        padding: 0 20px;
        max-width: 600px;
      }
    }
    .tag {
      button {
        background: #f1f5fd;
        border: 0.5px solid #747e94;
        border-radius: 13px;
        padding: 6px 19px;
        @include Gilroy-Regular;
        font-size: 12px;
        color: #000000;
        margin-right: 11px;
        margin-bottom: 10px;
        &.active {
          color: #ff8a00;
          border: 0.5px solid #ff8a00;
          background: rgba(255, 138, 0, 0.09);
        }
      }
    }
    .results-card {
      padding-top: 20px;
      .date-filter {
        background-color: #fff;
        width: 60%;
        box-shadow: 0px 15px 41px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 30px;
        margin-bottom: 40px;
        @include mobile-900 {
          width: 100%;
        }
        .date-filter-cover {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include mobile-550 {
            flex-wrap: wrap;
            text-align: center;
            p {
              width: 100%;
              padding: 10px 0;
            }
          }
          .cardx {
            @include mobile-550 {
              width: 100%;
            }
          }
          .submit {
            @include mobile-550 {
              width: 100%;
              text-align: center;
              padding-top: 20px;
              button {
                width: 100%;
              }
            }
          }
          input {
            width: 160px;
            background: #f7f9fe;
            border: 1px solid #c8c8c8;
            border-radius: 11px;
            outline: none;
            height: 50px;
            padding: 0 10px;
            @include mobile-550 {
              width: 100%;
            }
          }
          button {
            height: 50px;
            padding: 0 20px;
            background-color: #ff8a00;
            border: none;
            border-radius: 11px;
          }
        }
      }
      .card {
        border: 0.5px solid #a8a8a8;
        border-radius: 8px;
        padding: 33px;
        margin-bottom: 16px;
        cursor: pointer;

        & h1 {
          @include Gilroy-Bold;
          font-size: 20px;
          color: #000000;
          padding-bottom: 11px;
          span {
            color: #ff8a00;
            @include Gilroy-Light;
          }
        }
        p {
          @include Gilroy-Regular;
          font-size: 15px;
          color: #000000;
          padding-bottom: 11px;
        }
        .buttons {
          text-align: right;
          button {
            border-radius: 13px;
            font-size: 12px;
            @include Gilroy-Regular;
            padding: 4px 12px;
            border: none;
            background: rgba(255, 0, 0, 0.1);
            color: #ff0000;
          }
        }
        &.linked {
          background: #ffffff;
          border: none;
          .buttons {
            button {
              background: rgba(57, 187, 70, 0.15);
              color: #39bb46;
            }
          }
        }
      }
      #blur {
        color: transparent;
        text-shadow: 0 0 8px #000;
        cursor: pointer;
        & h1,
        p {
          color: transparent;
          text-shadow: 0 0 8px #000;
          & span {
            color: transparent;
          }
        }
      }
    }
  }
}
