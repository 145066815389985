@import '../../../../resources';
.taxpayerDrill {
  background: #f1f5fd;
  width: 100%;
  max-width: 800px;
  border-radius: 7px;
  padding: 15px;
  height: max-content;
  .top {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      @include Gilroy-Bold;
      font-size: 24px;
      letter-spacing: 0.02em;
      word-break: break-all;
    }
    svg {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .home_card {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    flex-wrap: wrap;
    .card {
      display: flex;
      width: 32%;
      background-color: #fff;
      padding: 25px;
      border-radius: 10px;
      margin-bottom: 10px;
      @include mobile-800 {
        width: 49%;
      }
      @include mobile-600 {
        width: 100%;
      }
      .left {
        margin-right: 13px;
        .round {
          width: 40px;
          height: 40px;
          background-color: #ffe5c6;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 20px;
            color: #b37027;
          }
        }
        &.dif {
          .round {
            background-color: #dbffc6;
            svg {
              color: #7aa360;
            }
          }
        }
      }
      .right {
        p {
          letter-spacing: 0.02em;
          @include Gilroy-Regular;
          font-size: 14px;
          padding-bottom: 5px;
        }
        h2 {
          @include Gilroy-Bold;
          font-size: 24px;
          letter-spacing: 0.02em;
          word-break: break-all;
        }
      }
    }
    .cards {
      display: flex;
      background-color: #fff;
      padding: 25px;
      border-radius: 10px;
      margin-bottom: 10px;
      width: 50%;
      @include mobile-600 {
        width: 100%;
      }
      .left {
        margin-right: 13px;
        .round {
          width: 40px;
          height: 40px;
          background-color: #ffe5c6;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 20px;
            color: #b37027;
          }
        }
        &.dif {
          .round {
            background-color: #dbffc6;
            svg {
              color: #7aa360;
            }
          }
        }
      }
      .right {
        p {
          letter-spacing: 0.02em;
          @include Gilroy-Regular;
          font-size: 14px;
          padding-bottom: 5px;
        }
        h2 {
          @include Gilroy-Bold;
          font-size: 24px;
          letter-spacing: 0.02em;
          word-break: break-all;
        }
      }
    }
  }
  .inputs {
    h2 {
      @include Gilroy-Bold;
      font-size: 20px;
      letter-spacing: 0.02em;
      word-break: break-all;
      padding-bottom: 30px;
    }
    .top_form {
      .top_four {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .cards {
          width: 48%;
          margin-bottom: 23px;
          @include mobile-420 {
            width: 100%;
          }
          p {
            font-size: 12px;
            @include Gilroy-Light;
            text-transform: uppercase;
            padding-bottom: 9px;
          }
          input,
          select {
            width: 100%;
            background: #f5f5f5;
            border: 0.5px solid #a7a7a7;
            border-radius: 3px;
            padding: 10px 20px;
            font-size: 16px;
            @include Gilroy-Regular;
          }
        }
      }
    }
  }
}
