@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,500;0,700;1,400&display=swap');
@font-face {
  font-family: 'Gilroy-Regular';
  src: url('./Fonts/Gilroy-Regular.ttf');
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: url('./Fonts/Gilroy-Medium.ttf');
}
@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./Fonts/Gilroy-Bold.ttf');
}
@font-face {
  font-family: 'Gilroy-Light';
  src: url('./Fonts/Gilroy-Light.ttf');
}

$mobile-420: 420px;
$mobile-550: 550px;
$mobile-600: 600px;
$mobile-800: 800px;
$mobile-900: 900px;
$mobile-1024: 1024px;
$main-color: #ff8a00;
@mixin mobile-420 {
  @media (max-width: $mobile-420) {
    @content;
  }
}
@mixin mobile-550 {
  @media (max-width: $mobile-550) {
    @content;
  }
}
@mixin mobile-600 {
  @media (max-width: $mobile-600) {
    @content;
  }
}
@mixin mobile-800 {
  @media (max-width: $mobile-800) {
    @content;
  }
}
@mixin mobile-900 {
  @media (max-width: $mobile-900) {
    @content;
  }
}
@mixin mobile-1024 {
  @media (max-width: $mobile-1024) {
    @content;
  }
}

@mixin Roboto {
  font-family: 'Roboto', sans-serif;
}
@mixin Gilroy-Regular {
  font-family: 'Gilroy-Regular', sans-serif;
}
@mixin Gilroy-Medium {
  font-family: 'Gilroy-Medium', sans-serif;
}
@mixin Gilroy-Bold {
  font-family: 'Gilroy-Bold', sans-serif;
}
@mixin Gilroy-Light {
  font-family: 'Gilroy-Light', sans-serif;
}
