@import '../../../../resources';
.datAdmin {
  padding: 0 50px;
  margin-bottom: 50px;
  position: relative;
  @include mobile-1024 {
    padding: 0 20px;
  }
  .home_tag {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
    h1 {
      @include Gilroy-Bold;
      font-size: 28px;
    }
  }
  .cover {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 25px 30px;
    overflow-x: auto;
    .home_button {
      button {
        @include Gilroy-Regular;
        padding: 12px 23px;
        background: #f1f5fd;
        border: 1px solid #afafaf;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 19px;
        margin-bottom: 20px;
      }
    }
  }
}
