@import '../../../../resources';
.home_page {
  padding: 0 50px;
  margin-bottom: 50px;
  @include mobile-800 {
    padding: 0 20px;
  }
  .home_button {
    margin-bottom: 19px;
    button {
      @include Gilroy-Regular;
      padding: 12px 23px;
      background: #f1f5fd;
      border: 1px solid #afafaf;
      border-radius: 4px;
      font-size: 14px;
      margin-right: 19px;
      margin-bottom: 20px;
    }
  }
  .home_tag {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
    h1 {
      @include Gilroy-Bold;
      font-size: 28px;
    }
    .home_select {
      @include mobile-600 {
        width: 100%;
        padding-top: 10px;
        text-align: right;
      }
      select {
        background: #ffffff;
        border: 0.5px solid #dedede;
        border-radius: 6px;
        padding: 10px 20px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
  .home_card {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    .card {
      display: flex;
      width: 32%;
      background-color: #fff;
      padding: 25px;
      border-radius: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      @include mobile-800 {
        width: 49%;
      }
      @include mobile-600 {
        width: 100%;
      }
      .left {
        margin-right: 13px;
        .round {
          width: 40px;
          height: 40px;
          background-color: #ffe5c6;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 20px;
            color: #b37027;
          }
        }
        &.dif {
          .round {
            background-color: #dbffc6;
            svg {
              color: #7aa360;
            }
          }
        }
      }
      .right {
        p {
          letter-spacing: 0.02em;
          @include Gilroy-Regular;
          font-size: 12px;
          padding-bottom: 5px;
          word-break: break-all;
        }
        h2 {
          @include Gilroy-Bold;
          font-size: 24px;
          letter-spacing: 0.02em;
          word-break: break-all;
        }
      }
    }
  }
  .paginations {
    margin-bottom: 30px;
  }
  .home_stats {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: 61%;
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      @include mobile-800 {
        width: 100%;
        margin-bottom: 30px;
      }
      .left_top {
        padding-bottom: 20px;
        h2 {
          @include Gilroy-Bold;
          font-size: 20px;
        }
      }
      .chart-card {
        width: 100%;
        @include Gilroy-Regular;
      }
    }

    .right {
      width: 37%;
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      @include mobile-800 {
        width: 100%;
      }
      .right_top {
        padding-bottom: 20px;
        h2 {
          @include Gilroy-Bold;
          font-size: 20px;
        }
      }
      .right_bottom {
        padding-top: 20px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        h2 {
          @include Gilroy-Bold;
          font-size: 28px;
          padding-bottom: 4px;
          color: #000;
        }
        p {
          @include Gilroy-Regular;
          color: #9a9a9a;
          font-size: 14px;
        }
        .one {
          padding-bottom: 10px;
          h2 {
            color: #ff8a00;
          }
        }
      }
    }
    &.dif {
      margin-top: 40px;
      .right {
        width: 48%;
        .right_bottom {
          justify-content: unset;
          .card {
            display: flex;
            align-items: center;
            margin-right: 20px;
            div {
              margin-right: 5px;
            }
            svg {
              color: #ff8a00;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  .home_tables {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;
    margin-bottom: 30px;
    .left {
      width: 100%;
      background-color: #fff;
      border-radius: 10px;
      padding: 25px 30px;
      overflow-x: auto;
      @include mobile-800 {
        width: 100%;
        margin-bottom: 30px;
      }
      .left_top {
        padding-bottom: 20px;
        h2 {
          @include Gilroy-Bold;
          font-size: 20px;
        }
      }
      table {
        width: 100%;
        thead {
          tr {
            th {
              @include Gilroy-Medium;
              font-size: 16px;
              padding: 20px 10px;
              text-align: left;
            }
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background-color: #f5f9ff;
            }
            td {
              @include Gilroy-Medium;
              font-size: 14px;
              padding: 10px 10px;
            }
          }
        }
      }
    }
  }
}
