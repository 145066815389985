@import '../../../../resources';
.paymenttransaction {
  margin-bottom: 50px;
  position: relative;
  @include mobile-1024 {
    padding: 0 20px;
  }
  .overlays {
    position: absolute;
    z-index: 8;
    height: 100%;
    width: 100%;
    background: #f1f5fdd3;
    justify-content: center;
    display: flex;
    top: 0;
    .userSearch {
      background: #fff;
      height: fit-content;
      width: 100%;
      max-width: 800px;
      border-radius: 7px;
      padding: 15px;

      @media screen and (max-height: 600px) {
        margin-top: 100px;
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.5px solid #e1e1e1;
        padding-bottom: 10px;
        margin-bottom: 40px;
        p {
          font-size: 16px;
          @include Gilroy-Medium;
          color: #535353;
          border-left: 1px solid #535353;
          padding-left: 10px;
        }
        svg {
          font-size: 20px;
          cursor: pointer;
        }
      }
      .form {
        .tag {
          @include Roboto;
          h2 {
            font-size: 20px;
            padding-bottom: 10px;
          }
        }
        .form_wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          border-bottom: 1px solid #c8c8c8;
          padding-bottom: 20px;
          .card {
            width: 32%;
            margin-bottom: 20px;
            @include mobile-800 {
              width: 48%;
            }
            input,
            select {
              @include Roboto;
              font-size: 14px;
              background: #f6f9fe;
              border: 1px solid #b4b4b4;
              border-radius: 4px;
              padding: 15px 25px;
              width: 100%;
              &::placeholder {
                color: #000;
              }
            }
            button {
              background-color: #ff8a00;
            }
          }
        }
        .form_wrapper1 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-bottom: 20px;
          .card {
            width: 40%;
            margin-bottom: 20px;
            @include mobile-800 {
              width: 48%;
            }
            input,
            select {
              @include Roboto;
              font-size: 14px;
              background: #f6f9fe;
              border: 1px solid #b4b4b4;
              border-radius: 4px;
              padding: 15px 25px;
              width: 100%;
              &::placeholder {
                color: #000;
              }
            }
          }
          .button {
            width: 16%;
            margin-bottom: 20px;
            @include mobile-800 {
              width: 48%;
            }

            button {
              @include Roboto;
              width: 100%;
              height: 100%;
              background: #ff8a00;
              color: #fff;
              border: 1px solid #ff8a00;
              font-size: 16px;
            }
          }
        }
        .form_wrapper2 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-bottom: 20px;
          .card {
            width: 80%;
            margin-bottom: 20px;
            @include mobile-800 {
              width: 48%;
            }
            input,
            select {
              @include Roboto;
              font-size: 14px;
              background: #f6f9fe;
              border: 1px solid #b4b4b4;
              border-radius: 4px;
              padding: 15px 25px;
              width: 100%;
              &::placeholder {
                color: #000;
              }
            }
          }
          .button {
            width: 16%;
            margin-bottom: 20px;
            @include mobile-800 {
              width: 48%;
            }

            button {
              @include Roboto;
              width: 100%;
              height: 100%;
              background: #ff8a00;
              color: #fff;
              border: 1px solid #ff8a00;
              font-size: 16px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 20px 0;
          button {
            margin-left: 20px;
            @include Gilroy-Regular;
            font-size: 14px;
            border: none;
            background: none;
            &.save {
              padding: 8px 16px;
              background: #ff8a00;
              border-radius: 21px;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .home_button {
    padding: 0 50px;
    margin-bottom: 19px;
    @include mobile-1024 {
      padding: 0 20px;
    }
    button {
      @include Gilroy-Regular;
      padding: 12px 23px;
      background: #f1f5fd;
      border: 1px solid #afafaf;
      border-radius: 4px;
      font-size: 14px;
      margin-right: 19px;
      margin-bottom: 20px;
    }
  }
  .home_card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 50px 20px 50px;
    @include mobile-1024 {
      margin: 0 20px 20px 0px;
    }
    .card {
      display: flex;
      width: 32%;
      background-color: #fff;
      padding: 25px;
      border-radius: 10px;
      margin-bottom: 10px;
      @include mobile-800 {
        width: 49%;
      }
      @include mobile-600 {
        width: 100%;
      }
      button {
        @include Gilroy-Regular;
        font-size: 14px;
        padding: 10px 16px;
        background: #ff8a00;
        color: #fff;
        border: none;
      }
      .left {
        margin-right: 13px;
        .round {
          width: 40px;
          height: 40px;
          background-color: #ffe5c6;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 20px;
            color: #b37027;
          }
        }
        &.dif {
          .round {
            background-color: #dbffc6;
            svg {
              color: #7aa360;
            }
          }
        }
      }
      .right {
        p {
          letter-spacing: 0.02em;
          @include Gilroy-Regular;
          font-size: 14px;
          padding-bottom: 5px;
        }
        h2 {
          @include Gilroy-Bold;
          font-size: 24px;
          letter-spacing: 0.02em;
          word-break: break-all;
        }
      }
    }
  }
  .search_top {
    background-color: #fff;
    box-shadow: 0px 15px 41px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 30px;
    margin: 0 50px 40px 50px;
    @include mobile-1024 {
      margin: 0 20px 40px 0px;
    }
    .form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: #f7f9fe;
      border: 1px solid #c8c8c8;
      border-radius: 11px;
      padding: 10px 10px 10px 30px;
      input {
        width: 90%;
        border: none;
        border-left: 1px solid #535353;
        background: none;
        padding: 6px 10px;
        outline: none;
      }
      button {
        width: 5%;
        background: none;
        border: none;
        svg {
          font-size: 20px;
        }
      }
    }
    .count {
      padding-top: 10px;
      h1 {
        @include Roboto;
        font-size: 20px;
        span {
          color: #ff8a00;
          padding-left: 10px;
          font-size: 25px;
        }
      }
    }
    .advace {
      padding-top: 10px;
      button {
        @include Gilroy-Regular;
        font-size: 14px;
        padding: 10px 16px;
        background: #ff8a00;
        color: #fff;
        border: none;
      }
    }
  }
  .table {
    overflow-x: auto;
    padding: 0 50px;
    @include mobile-1024 {
      padding: 0 20px;
    }
    table {
      width: 100%;
      text-align: left;
      thead {
        tr {
          background-color: #dbe2ef;
          border-radius: 4px;
          th {
            padding: 10px;
            @include Roboto;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      tbody {
        tr {
          background-color: #fff;
          border-radius: 4px;
          cursor: pointer;
          td {
            padding: 10px;
            @include Gilroy-Regular;
            font-weight: 400;
            font-size: 14px;
            text-transform: capitalize;
          }
          &.blur {
            color: transparent;
            text-shadow: 0 0 8px #000;
            cursor: pointer;
          }
        }
      }
    }
  }
  .paginations {
    padding: 30px 50px 0 50px;
    @include mobile-1024 {
      padding: 30px 20px 0 20px;
    }
  }
}
.create_payment {
  padding: 0 50px;
  margin-bottom: 50px;
  position: relative;
  @include mobile-1024 {
    padding: 0 20px;
  }
  .back {
    padding-bottom: 20px;
    .back_left {
      display: flex;
      align-items: center;
      @include mobile-800 {
        padding-bottom: 20px;
      }
      h1 {
        @include Gilroy-Bold;
        font-size: 25px;
        margin-left: 15px;
      }
      svg {
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
  .enroll_main {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    position: relative;
    .left {
      width: 100%;
      border-right: 1px solid #e9e9e9;
      @include mobile-1024 {
        width: 100%;
      }
      .left_top {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e9e9e9;
        padding: 0 30px 30px 30px;
        svg {
          font-size: 20px;
        }
        h2 {
          @include Gilroy-Bold;
          font-size: 20px;
          margin-left: 10px;
        }
        &.diff2 {
          border-bottom: 1px solid #e9e9e9;
          padding: 0 0px 20px 0px;
          margin-bottom: 30px;
        }
      }
      .top_form {
        padding: 40px 30px;
        .card {
          width: 32%;
          margin-bottom: 23px;
          @include mobile-800 {
            width: 48%;
          }
          @include mobile-420 {
            width: 100%;
          }
          p {
            font-size: 12px;
            @include Gilroy-Light;
            padding-bottom: 9px;
          }
          input,
          select {
            width: 100%;
            background: #f5f5f5;
            border: 0.5px solid #a7a7a7;
            border-radius: 3px;
            padding: 10px 20px;
            font-size: 16px;
            @include Gilroy-Regular;
          }
          button {
            width: 100%;
            background: red;
            border: 0.5px solid #a7a7a7;
            border-radius: 3px;
            padding: 10px 20px;
            font-size: 16px;
            color: #fff;
            @include Gilroy-Regular;
          }
        }
        .remove {
          width: 100%;
          text-align: right;
          p {
            color: red;
            font-size: 15px;
            cursor: pointer;
          }
        }
        .top_six {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          &.diff {
            .card {
              width: 23%;
              @include mobile-800 {
                width: 48%;
              }
              @include mobile-420 {
                width: 100%;
              }
            }
          }
        }
        .top_three {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: flex-end;
        }

        .address {
          width: 100%;
          margin-bottom: 23px;
          p {
            font-size: 12px;
            @include Gilroy-Light;
            padding-bottom: 9px;
          }
          input,
          select {
            width: 100%;
            background: #f5f5f5;
            border: 0.5px solid #a7a7a7;
            border-radius: 3px;
            padding: 10px 20px;
            font-size: 16px;
            @include Gilroy-Regular;
          }
        }
        .top_four {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .cards {
            width: 48%;
            margin-bottom: 23px;
            @include mobile-420 {
              width: 100%;
            }
            p {
              font-size: 12px;
              @include Gilroy-Light;
              padding-bottom: 9px;
            }
            input,
            select {
              width: 100%;
              background: #f5f5f5;
              border: 0.5px solid #a7a7a7;
              border-radius: 3px;
              padding: 10px 20px;
              font-size: 16px;
              @include Gilroy-Regular;
            }
          }
        }
        .child_add {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
          .cards {
            width: 45%;
            margin-bottom: 23px;
            @include mobile-420 {
              width: 100%;
            }
            p {
              font-size: 12px;
              @include Gilroy-Light;
              padding-bottom: 9px;
            }
            input,
            select {
              width: 100%;
              background: #f5f5f5;
              border: 0.5px solid #a7a7a7;
              border-radius: 3px;
              padding: 10px 20px;
              font-size: 16px;
              @include Gilroy-Regular;
            }
          }
          .remove-butt {
            font-size: 11px;
            color: red;
            cursor: pointer;
          }
        }
        .child_button {
          margin-bottom: 23px;
          .button {
            background: none;
            border: 1px solid #ff8a00;
            color: #ff8a00;
            font-size: 14px;
            width: fit-content;
            padding: 10px;
            cursor: pointer;
          }
        }
        .top_diff {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .cards {
            width: 67%;
            margin-bottom: 23px;
            @include mobile-420 {
              width: 100%;
            }
            p {
              font-size: 12px;
              @include Gilroy-Light;
              padding-bottom: 9px;
            }
            input,
            select {
              width: 100%;
              background: #f5f5f5;
              border: 0.5px solid #a7a7a7;
              border-radius: 3px;
              padding: 10px 20px;
              font-size: 16px;
              @include Gilroy-Regular;
            }
          }
          .cardt {
            width: 30%;
            margin-bottom: 23px;
            @include mobile-420 {
              width: 100%;
            }
            p {
              font-size: 12px;
              @include Gilroy-Light;
              padding-bottom: 9px;
            }
            input,
            select {
              width: 100%;
              background: #f5f5f5;
              border: 0.5px solid #a7a7a7;
              border-radius: 3px;
              padding: 10px 20px;
              font-size: 16px;
              @include Gilroy-Regular;
            }
          }
        }
        .estra {
          border-top: 1px solid #d7d4d4;
          padding-top: 10px;
          h2 {
            @include Gilroy-Bold;
            font-size: 20px;
            padding-bottom: 10px;
          }
          table {
            width: 100%;
            text-align: left;
            border: 1px solid #d7d4d4;
            margin-top: 20px;
            thead {
              tr {
                background-color: #dbe2ef;
                border-radius: 4px;
                th {
                  padding: 10px;
                  .div {
                    display: flex;
                    align-items: center;
                  }
                  p {
                    @include Roboto;
                    font-weight: 400;
                    font-size: 16px;
                    padding-right: 10px;
                  }
                  svg {
                    cursor: pointer;
                  }
                }
              }
            }
            tbody {
              tr {
                background-color: #fff;
                border-radius: 4px;
                cursor: pointer;
                td {
                  padding: 10px;
                  @include Gilroy-Regular;
                  font-weight: 400;
                  font-size: 14px;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
      .submit {
        display: flex;
        justify-content: right;
        padding: 10px 30px;
        button {
          @include Gilroy-Regular;
          font-size: 14px;
          border: none;
          padding: 10px 30px;
          background: #ff8a00;
          border: 1px solid #ff8a00;
          border-radius: 21px;
          color: #fff;
          display: flex;
          p {
            padding-right: 5px;
          }
        }
      }
    }
    .formload {
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.548);
      display: flex;
      justify-content: center;
      padding-top: 30%;
      img {
        width: 200px;
        height: 200px;
      }
    }
  }
}
