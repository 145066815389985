@import '../../../../resources';
.paginations {
  padding-top: 30px;
  display: flex;
  justify-content: right;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      @include Roboto;
      font-size: 12px;
      font-weight: bold;
      list-style: none;
      padding: 6px 10px;
      border: 1px solid #000;
      margin-right: 5px;
      cursor: pointer;
      &:hover {
        border: none;
        background-color: #ff8a00;
        border: 1px solid #ff8a00;
        color: #535353;
      }
      &.active {
        background-color: #ff8a00;
        border: 1px solid #ff8a00;
        color: #fff;
      }
      &.disable {
        background-color: #fff;
        border: 1px solid #b7b6b6;
        color: #b7b6b6;
        display: none;
      }
    }
  }
}
