@import '../../../../resources';
.admin_user {
  padding: 0 50px;
  margin-bottom: 50px;
  @include mobile-800 {
    padding: 0 20px;
  }
  .top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    h1 {
      @include Gilroy-Bold;
      font-size: 28px;
      @include mobile-600 {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    .add {
      button {
        padding: 10px 20px;
        background: #ff8a00;
        border-radius: 21px;
        border: none;
        @include Gilroy-Regular;
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .table {
    overflow-x: auto;
    table {
      width: 100%;
      text-align: left;
      thead {
        tr {
          background-color: #dbe2ef;
          border-radius: 4px;
          th {
            padding: 10px;
            @include Roboto;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      tbody {
        tr {
          background-color: #fff;
          border-radius: 4px;
          td {
            padding: 10px;
            @include Gilroy-Regular;
            font-weight: 400;
            font-size: 14px;
            &.status {
              display: flex;
              align-items: center;
              .dot {
                padding-right: 5px;
                svg {
                  color: #05ff00;
                }
              }
              .dots {
                padding-right: 5px;
                svg {
                  color: red;
                }
              }
            }
            &.actions {
              svg {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .pagination {
    padding-top: 30px;
    display: flex;
    justify-content: right;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        @include Roboto;
        font-size: 12px;
        font-weight: bold;
        list-style: none;
        padding: 6px 10px;
        border: 1px solid #000;
        margin-right: 5px;
        cursor: pointer;
        &:hover {
          background-color: #fff;
          border: none;
          border: 1px solid #535353;
          color: #535353;
        }
        &.active {
          background-color: #fff;
          border: none;
          border: 1px solid #535353;
          color: #535353;
        }
      }
    }
  }
}
.loader {
  background: #edebeb;
  margin-top: -100px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
