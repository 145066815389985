@import '../../../../resources';
.add_user {
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  border-radius: 7px;
  padding: 15px;
  overflow-y: auto;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #e1e1e1;
    padding-bottom: 10px;
    margin-bottom: 20px;
    h1 {
      font-size: 20px;
      @include Gilroy-Bold;
    }
    svg {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include Roboto;
    .card {
      width: 48%;
      margin-bottom: 20px;
      @include mobile-600 {
        width: 100%;
      }
      h6 {
        color: red;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 5px;
      }
      select,
      input,
      textarea {
        border: 1px solid #b4b4b4;
        border-radius: 4px;
        width: 100%;
        padding: 10px 20px;
        resize: none;
        &::placeholder {
          @include Roboto;
        }
      }
      &.long {
        width: 100%;
      }
      .inputs {
        display: flex;
        align-items: center;
        button {
          margin-left: 40px;
          padding: 5px 5px;
          background: none;
          border: 1px solid #ff8a00;
          border-radius: 5px;
          color: #ff8a00;
          font-size: 11px;
        }
      }
      .showAdded {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
      }
    }
    .buttons {
      text-align: right;
      width: 100%;
      button,
      .button {
        margin-left: 20px;
        @include Gilroy-Regular;
        font-size: 14px;
        border: none;
        background: none;
        &.save {
          padding: 8px 16px;
          background: #ff8a00;
          border-radius: 21px;
          color: #fff;
        }
      }
    }
    .loaders {
      text-align: right;
      height: fit-content;
      display: flex;
      justify-content: right;
      width: 100%;
      img {
        width: 30px;
      }
    }
  }
}
