@import '../../../../resources';
.enrollPayer {
  padding: 0 50px;
  margin-bottom: 50px;
  @include mobile-800 {
    padding: 0 20px;
  }
  .top_button {
    text-align: right;
    button {
      @include Gilroy-Regular;
      padding: 12px 23px;
      background: #f1f5fd;
      border: 1px solid #afafaf;
      border-radius: 4px;
      font-size: 14px;
      margin-bottom: 40px;
    }
  }
  .upload_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    .left {
      display: flex;
      align-items: center;
      @include mobile-800 {
        padding-bottom: 20px;
      }
      h1 {
        @include Gilroy-Bold;
        font-size: 25px;
        margin-left: 15px;
      }
      svg {
        cursor: pointer;
      }
    }
    .right {
      display: flex;
      align-items: center;
      border: 0.5px solid #b4b4b4;
      border-radius: 5px;
      padding: 0px 25px;
      flex-wrap: wrap;
      .card {
        display: flex;
        margin-left: 10px;
        padding: 10px;
        cursor: pointer;

        p {
          @include Gilroy-Regular;
          padding-left: 10px;
          color: #535353;
          font-size: 14px;
        }
        svg {
          color: #535353;
        }
        &.active {
          p {
            color: #ff8a00;
          }
          svg {
            color: #ff8a00;
          }
        }
      }
      .line {
        height: 1px;
        width: 40px;
        margin-left: 10px;
        background-color: #b4b4b4;
      }
    }
  }
  .enroll_main {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap-reverse;
    position: relative;
    .submit {
      display: flex;
      justify-content: right;
      padding: 30px;
      button {
        @include Gilroy-Regular;
        font-size: 14px;
        border: none;
        padding: 10px 30px;
        background: #ff8a00;
        border: 1px solid #ff8a00;
        border-radius: 21px;
        color: #fff;
        display: flex;
        p {
          padding-right: 5px;
        }
      }
    }
    .left {
      width: 70%;
      border-right: 1px solid #e9e9e9;
      padding: 0;
      @include mobile-1024 {
        width: 100%;
      }
      &.diff {
        width: 100%;
      }
      .left_top {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e9e9e9;
        padding: 0 30px 30px 30px;
        svg {
          font-size: 20px;
        }
        h2 {
          @include Gilroy-Bold;
          font-size: 20px;
          margin-left: 10px;
        }
        &.diff2 {
          border-bottom: 1px solid #e9e9e9;
          padding: 0 0px 20px 0px;
          margin-bottom: 30px;
        }
      }
      .top_form {
        padding: 20px 30px;
        .card {
          width: 32%;
          margin-bottom: 23px;
          @include mobile-800 {
            width: 48%;
          }
          @include mobile-420 {
            width: 100%;
          }
          p {
            font-size: 12px;
            text-transform: uppercase;
            @include Gilroy-Light;
            padding-bottom: 9px;
          }
          span {
            font-size: 8px;
          }
          input,
          select {
            width: 100%;
            background: #f5f5f5;
            border: 0.5px solid #a7a7a7;
            border-radius: 3px;
            padding: 10px 20px;
            font-size: 16px;
            @include Gilroy-Regular;
          }
          button {
            width: 100%;
            background: red;
            border: 0.5px solid #a7a7a7;
            border-radius: 3px;
            padding: 10px 20px;
            font-size: 16px;
            color: #fff;
            @include Gilroy-Regular;
          }
        }
        .remove {
          width: 100%;
          text-align: right;
          p {
            color: red;
            font-size: 15px;
            cursor: pointer;
          }
        }
        .top_six {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          &.diff {
            .card {
              width: 23%;
              @include mobile-800 {
                width: 48%;
              }
              @include mobile-420 {
                width: 100%;
              }
            }
          }
        }
        .address {
          width: 100%;
          margin-bottom: 23px;
          p {
            font-size: 12px;
            @include Gilroy-Light;
            padding-bottom: 9px;
          }
          input,
          select {
            width: 100%;
            background: #f5f5f5;
            border: 0.5px solid #a7a7a7;
            border-radius: 3px;
            padding: 10px 20px;
            font-size: 16px;
            @include Gilroy-Regular;
          }
        }
        .top_four {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .cards {
            width: 48%;
            margin-bottom: 23px;
            @include mobile-420 {
              width: 100%;
            }
            p {
              font-size: 12px;
              @include Gilroy-Light;
              text-transform: uppercase;
              padding-bottom: 9px;
            }
            input,
            select {
              width: 100%;
              background: #f5f5f5;
              border: 0.5px solid #a7a7a7;
              border-radius: 3px;
              padding: 10px 20px;
              font-size: 16px;
              @include Gilroy-Regular;
            }
          }
        }
        .child_add {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
          .cards {
            width: 45%;
            margin-bottom: 23px;
            @include mobile-420 {
              width: 100%;
            }
            p {
              font-size: 12px;
              @include Gilroy-Light;
              padding-bottom: 9px;
              text-transform: uppercase;
            }
            input,
            select {
              width: 100%;
              background: #f5f5f5;
              border: 0.5px solid #a7a7a7;
              border-radius: 3px;
              padding: 10px 20px;
              font-size: 16px;
              @include Gilroy-Regular;
            }
          }
          .remove-butt {
            font-size: 11px;
            color: red;
            cursor: pointer;
          }
        }
        .child_button {
          .button {
            background: none;
            border: 1px solid #ff8a00;
            color: #ff8a00;
            font-size: 14px;
            width: fit-content;
            padding: 10px;
            cursor: pointer;
          }
        }
        .top_diff {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .cards {
            width: 67%;
            margin-bottom: 23px;
            @include mobile-420 {
              width: 100%;
            }
            p {
              font-size: 12px;
              @include Gilroy-Light;
              padding-bottom: 9px;
            }
            input,
            select {
              width: 100%;
              background: #f5f5f5;
              border: 0.5px solid #a7a7a7;
              border-radius: 3px;
              padding: 10px 20px;
              font-size: 16px;
              @include Gilroy-Regular;
            }
          }
          .cardt {
            width: 30%;
            margin-bottom: 23px;
            @include mobile-420 {
              width: 100%;
            }
            p {
              font-size: 12px;
              @include Gilroy-Light;
              padding-bottom: 9px;
            }
            input,
            select {
              width: 100%;
              background: #f5f5f5;
              border: 0.5px solid #a7a7a7;
              border-radius: 3px;
              padding: 10px 20px;
              font-size: 16px;
              @include Gilroy-Regular;
            }
          }
        }
      }
      .submit {
        display: flex;
        justify-content: right;
        padding: 10px 30px;
        button {
          @include Gilroy-Regular;
          font-size: 14px;
          border: none;
          padding: 10px 30px;
          background: #ff8a00;
          border: 1px solid #ff8a00;
          border-radius: 21px;
          color: #fff;
          display: flex;
          p {
            padding-right: 5px;
          }
        }
      }
    }
    .right {
      width: 30%;
      padding: 30px 0;
      @include mobile-1024 {
        width: 100%;
      }
      .right_top {
        text-align: center;
        h2 {
          @include Gilroy-Bold;
          font-size: 20px;
          margin-left: 10px;
        }
      }
      .image_container {
        text-align: center;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        input {
          display: none;
        }
        .imagee {
          height: 150px;
          width: 150px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23FF8A00FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .placeholder {
            background: #000;
            width: 130px;
            height: 130px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              color: #fff;
              font-size: 30px;
            }
          }
          img {
            width: 130px;
            height: 130px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
      .text {
        text-align: center;
        padding: 23px 20px;
        p {
          @include Gilroy-Light;
          font-size: 14px;
          line-height: 154%;
        }
      }
    }
    .formload {
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.548);
      display: flex;
      justify-content: center;
      padding-top: 30%;
      img {
        width: 200px;
        height: 200px;
      }
    }
  }
}
