@import '../../../../resources';
.userPage {
  display: flex;
  margin-top: -100px;
  position: relative;
  .left {
    width: 69%;
    padding: 100px 30px 50px 30px;
    height: 100vh;
    overflow-y: auto;
    @include mobile-800 {
      width: 100%;
      padding: 100px 20px 0 20px;
    }
    .top {
      display: flex;
      justify-content: right;
      @include mobile-800 {
        justify-content: space-between;
        align-items: center;
      }
      button {
        @include Gilroy-Regular;
        font-size: 14px;
        border: none;
        padding: 10px 30px;
        background: #ff8a00;
        border: 1px solid #ff8a00;
        border-radius: 21px;
        color: #fff;
        display: flex;
        p {
          padding-right: 5px;
        }
      }
      .burger {
        display: none;
        @include mobile-800 {
          display: block;
        }
        svg {
          font-size: 30px;
        }
      }
    }
    .back {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 35px;
      flex-wrap: wrap;
      .back_left {
        display: flex;
        align-items: center;
        @include mobile-800 {
          padding-bottom: 20px;
        }
        h1 {
          @include Gilroy-Bold;
          font-size: 25px;
          margin-left: 15px;
        }
        svg {
          cursor: pointer;
          font-size: 20px;
        }
      }
      .back_right {
        p {
          font-size: 16px;
          @include Gilroy-Regular;
        }
      }
    }
    .user_details {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      flex-wrap: wrap;
      border-bottom: 0.5px solid #a2a2a2;
      padding-bottom: 50px;
      margin-bottom: 30px;

      .user_left {
        width: 25%;
        position: relative;
        @include mobile-600 {
          width: 100%;
          margin-bottom: 20px;
        }
        .status {
          position: absolute;
          width: 100%;
          text-align: right;
          display: flex;
          justify-content: right;
          padding: 10px;
          div {
            border-radius: 3px;
            padding: 5px 10px;
            width: fit-content;
            &.Active {
              background: #39bb46;
            }
            &.Inactive {
              background: red;
            }
            p {
              @include Gilroy-Regular;
              font-size: 12px;
              color: #fff;
            }
          }
        }
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          object-position: center;
          border-radius: 7px;
        }
      }
      .user_right {
        width: 72%;
        background-color: #fff;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-radius: 5px;
        @include mobile-600 {
          width: 100%;
        }
        .card {
          width: 30%;
          margin-bottom: 20px;
          @include mobile-600 {
            width: 100%;
          }
          p {
            @include Gilroy-Regular;
            font-size: 13px;
            padding-bottom: 7px;
          }
          h2 {
            @include Gilroy-Bold;
            font-size: 18px;
          }
        }
      }
    }
    .information_page {
      .info {
        .info_top {
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          .icon {
            padding-right: 15px;
            display: flex;
            align-items: center;
            svg {
              font-size: 20px;
            }
          }
          h2 {
            font-size: 20px;
            @include Gilroy-Bold;
          }
          .info-top_wrap {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            .one {
              display: flex;
              align-items: center;
              .icon {
                padding-right: 15px;
                svg {
                  font-size: 20px;
                }
              }
              h2 {
                font-size: 20px;
                @include Gilroy-Bold;
              }
            }
            .two {
              button {
                @include Gilroy-Regular;
                font-size: 12px;
                border: none;
                padding: 10px 20px;
                color: #000;
                border: 1px solid #afafaf;
                border-radius: 4px;
                margin-left: 10px;
                background: #f1f5fd;
                &.active {
                  color: #fff;
                  background: #ff8a00;
                  border: none;
                }
              }
            }
          }
        }
        .info_wrap {
          .four_wrap {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .card {
              padding-bottom: 25px;
              padding-right: 20px;
              @include mobile-420 {
                width: 100%;
                padding-right: 0;
              }
              p {
                @include Gilroy-Regular;
                font-size: 13px;
                padding-bottom: 7px;
              }
              h3 {
                @include Gilroy-Bold;
                font-size: 18px;
              }
            }
          }
          .single_wrap {
            width: 100%;
            padding-bottom: 25px;
            p {
              @include Gilroy-Regular;
              font-size: 13px;
              padding-bottom: 7px;
            }
            h3 {
              @include Gilroy-Bold;
              font-size: 18px;
            }
          }
          .form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @include Roboto;
            .card {
              width: 48%;
              margin-bottom: 20px;
              @include mobile-600 {
                width: 100%;
              }
              h6 {
                color: red;
              }
              p {
                font-weight: 400;
                font-size: 14px;
                padding-bottom: 5px;
              }
              select,
              input {
                border: 1px solid #b4b4b4;
                border-radius: 4px;
                width: 100%;
                padding: 10px 20px;
                &::placeholder {
                  @include Roboto;
                }
              }
            }
            .buttons {
              text-align: right;
              width: 100%;
              button,
              .button {
                margin-left: 20px;
                @include Gilroy-Regular;
                font-size: 14px;
                border: none;
                background: none;
                &.save {
                  padding: 8px 16px;
                  background: #ff8a00;
                  border-radius: 21px;
                  color: #fff;
                }
              }
            }
            .loaders {
              text-align: right;
              height: fit-content;
              display: flex;
              justify-content: right;
              width: 100%;
              img {
                width: 70px;
              }
            }
          }
        }
        .info-table {
          overflow-x: auto;
          table {
            width: 100%;
            thead {
              tr {
                th {
                  @include Gilroy-Medium;
                  font-size: 16px;
                  padding: 20px 10px;
                  background-color: #fff;
                  min-width: 100px;
                }
              }
            }
            tbody {
              tr {
                td {
                  @include Gilroy-Medium;
                  font-size: 14px;
                  padding: 10px 10px;
                  background-color: #fff;
                  min-width: 100px;
                  &.count {
                    text-align: right;
                  }
                  button {
                    @include Gilroy-Regular;
                    font-size: 12px;
                    border: none;
                    padding: 10px 20px;
                    color: #000;
                    border: 1px solid #afafaf;
                    border-radius: 4px;
                    margin-left: 10px;
                    background: #f1f5fd;
                  }
                }
                &:nth-child(odd) {
                  td {
                    background-color: #f1f6ff;
                  }
                }
              }
            }
          }
          form {
            .top_form {
              padding: 20px 30px;
              .card {
                width: 32%;
                margin-bottom: 23px;
                @include mobile-800 {
                  width: 48%;
                }
                @include mobile-420 {
                  width: 100%;
                }
                p {
                  font-size: 12px;
                  @include Gilroy-Light;
                  padding-bottom: 9px;
                }
                input,
                select {
                  width: 100%;
                  background: #f5f5f5;
                  border: 0.5px solid #a7a7a7;
                  border-radius: 3px;
                  padding: 10px 20px;
                  font-size: 16px;
                  @include Gilroy-Regular;
                }
              }
              .remove {
                width: 100%;
                text-align: right;
                p {
                  color: red;
                  font-size: 15px;
                  cursor: pointer;
                }
              }
              .top_six {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
              }
              .address {
                width: 100%;
                margin-bottom: 23px;
                p {
                  font-size: 12px;
                  @include Gilroy-Light;
                  padding-bottom: 9px;
                }
                input,
                select {
                  width: 100%;
                  background: #f5f5f5;
                  border: 0.5px solid #a7a7a7;
                  border-radius: 3px;
                  padding: 10px 20px;
                  font-size: 16px;
                  @include Gilroy-Regular;
                }
              }
              .top_four {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .cards {
                  width: 48%;
                  margin-bottom: 23px;
                  @include mobile-420 {
                    width: 100%;
                  }
                  p {
                    font-size: 12px;
                    @include Gilroy-Light;
                    padding-bottom: 9px;
                  }
                  input,
                  select {
                    width: 100%;
                    background: #f5f5f5;
                    border: 0.5px solid #a7a7a7;
                    border-radius: 3px;
                    padding: 10px 20px;
                    font-size: 16px;
                    @include Gilroy-Regular;
                  }
                }
              }
            }
            .buttons {
              display: flex;
              justify-content: right;
              padding: 10px;
              button {
                @include Gilroy-Regular;
                font-size: 14px;
                border: none;
                padding: 10px 30px;
                background: #ff8a00;
                border: 1px solid #ff8a00;
                border-radius: 21px;
                color: #fff;
                display: flex;
                p {
                  padding-right: 5px;
                }
              }
            }
          }
          .card {
            padding: 10px;
            background-color: #fff;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 20px;
          }
        }
      }
      &.editd {
        padding-top: 20px;
        .info {
          .info_wrap {
            .top_form {
              padding: 40px 30px;
              .card {
                width: 32%;
                margin-bottom: 23px;
                @include mobile-800 {
                  width: 48%;
                }
                @include mobile-420 {
                  width: 100%;
                }
                p {
                  font-size: 12px;
                  @include Gilroy-Light;
                  padding-bottom: 9px;
                  text-transform: uppercase;
                }
                input,
                select {
                  width: 100%;
                  background: #f5f5f5;
                  border: 0.5px solid #a7a7a7;
                  border-radius: 3px;
                  padding: 10px 20px;
                  font-size: 16px;
                  @include Gilroy-Regular;
                }
                button {
                  width: 100%;
                  background: red;
                  border: 0.5px solid #a7a7a7;
                  border-radius: 3px;
                  padding: 10px 20px;
                  font-size: 16px;
                  color: #fff;
                  @include Gilroy-Regular;
                }
              }
              .remove {
                width: 100%;
                text-align: right;
                p {
                  color: red;
                  font-size: 15px;
                  cursor: pointer;
                }
              }
              .top_six {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
              }
              .address {
                width: 100%;
                margin-bottom: 23px;
                p {
                  font-size: 12px;
                  @include Gilroy-Light;
                  padding-bottom: 9px;
                }
                input,
                select {
                  width: 100%;
                  background: #f5f5f5;
                  border: 0.5px solid #a7a7a7;
                  border-radius: 3px;
                  padding: 10px 20px;
                  font-size: 16px;
                  @include Gilroy-Regular;
                }
              }
              .top_four {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .cards {
                  width: 48%;
                  margin-bottom: 23px;
                  @include mobile-420 {
                    width: 100%;
                  }
                  p {
                    font-size: 12px;
                    @include Gilroy-Light;
                    text-transform: uppercase;
                    padding-bottom: 9px;
                  }
                  input,
                  select {
                    width: 100%;
                    background: #f5f5f5;
                    border: 0.5px solid #a7a7a7;
                    border-radius: 3px;
                    padding: 10px 20px;
                    font-size: 16px;
                    @include Gilroy-Regular;
                  }
                }
              }
              .child_add {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                .cards {
                  width: 45%;
                  margin-bottom: 23px;
                  @include mobile-420 {
                    width: 100%;
                  }
                  p {
                    font-size: 12px;
                    @include Gilroy-Light;
                    padding-bottom: 9px;
                  }
                  input,
                  select {
                    width: 100%;
                    background: #f5f5f5;
                    border: 0.5px solid #a7a7a7;
                    border-radius: 3px;
                    padding: 10px 20px;
                    font-size: 16px;
                    @include Gilroy-Regular;
                  }
                }
                .remove-butt {
                  font-size: 11px;
                  color: red;
                  cursor: pointer;
                }
              }
              .child_button {
                display: flex;
                justify-content: right;
                .button {
                  background: none;
                  border: 1px solid #ff8a00;
                  color: #ff8a00;
                  font-size: 14px;
                  width: fit-content;
                  padding: 10px;
                  cursor: pointer;
                }
              }
              .top_diff {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .cards {
                  width: 67%;
                  margin-bottom: 23px;
                  @include mobile-420 {
                    width: 100%;
                  }
                  p {
                    font-size: 12px;
                    @include Gilroy-Light;
                    padding-bottom: 9px;
                  }
                  input,
                  select {
                    width: 100%;
                    background: #f5f5f5;
                    border: 0.5px solid #a7a7a7;
                    border-radius: 3px;
                    padding: 10px 20px;
                    font-size: 16px;
                    @include Gilroy-Regular;
                  }
                }
                .cardt {
                  width: 30%;
                  margin-bottom: 23px;
                  @include mobile-420 {
                    width: 100%;
                  }
                  p {
                    font-size: 12px;
                    @include Gilroy-Light;
                    padding-bottom: 9px;
                  }
                  input,
                  select {
                    width: 100%;
                    background: #f5f5f5;
                    border: 0.5px solid #a7a7a7;
                    border-radius: 3px;
                    padding: 10px 20px;
                    font-size: 16px;
                    @include Gilroy-Regular;
                  }
                }
              }
              .buttonx {
                button {
                  width: fit-content;
                  background: #39bb46;
                  border: 0.5px solid #a7a7a7;
                  border-radius: 3px;
                  padding: 10px 20px;
                  font-size: 16px;
                  color: #fff;
                  @include Gilroy-Regular;
                }
              }
            }
          }
        }
      }
    }
  }
  .right {
    width: 30%;
    background-color: #fff;
    height: 100vh;
    padding: 100px 20px 0px 20px;
    z-index: 1;
    @include mobile-800 {
      position: absolute;
      width: 35%;
      right: 100%;
      &.active {
        right: 0;
      }
    }
    @include mobile-600 {
      width: 60%;
    }
    @include mobile-420 {
      width: 80%;
    }
    .close {
      display: none;
      @include mobile-800 {
        display: block;
      }
      svg {
        font-size: 30px;
      }
    }
    .top {
      display: flex;
      justify-content: right;
      button {
        @include Gilroy-Regular;
        font-size: 14px;
        border: none;
        padding: 10px 30px;
        background: #ff8a00;
        border: 1px solid #ff8a00;
        border-radius: 21px;
        color: #fff;
        display: flex;
        p {
          padding-right: 5px;
        }
      }
    }
    .tag {
      padding-top: 35px;
      padding-bottom: 30px;
      h1 {
        @include Gilroy-Bold;
        font-size: 25px;
        @include mobile-420 {
          font-size: 20px;
        }
      }
    }
    .links {
      ul {
        background: #e6ecf8;
        border: 1px solid #dae3f6;
        border-radius: 10px;
        list-style: none;
        overflow: hidden;
        li {
          padding: 20px;
          font-size: 16px;
          @include Gilroy-Regular;
          border-bottom: 1px solid #dae3f6;
          cursor: pointer;
          &:nth-child(1) {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          &:nth-child(6) {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          &.active {
            background: #ff8a00;
            color: #fff;
          }
        }
      }
    }
  }
  .formload {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.548);
    display: flex;
    justify-content: center;
    padding-top: 30%;
    z-index: 2;
    img {
      width: 200px;
      height: 200px;
    }
  }
}
.userTransaction {
  padding: 0 50px;
  margin-bottom: 50px;
  position: relative;
  @include mobile-800 {
    padding: 0 20px;
  }
  .home_button {
    margin-bottom: 30px;
    h1 {
      @include Gilroy-Bold;
      font-size: 25px;
    }
  }
  .back {
    padding-bottom: 35px;
    .back_left {
      display: flex;
      align-items: center;
      @include mobile-800 {
        padding-bottom: 20px;
      }
      h1 {
        @include Gilroy-Bold;
        font-size: 20px;
        margin-left: 15px;
      }
      svg {
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
  .table {
    overflow-x: auto;
    table {
      width: 100%;
      text-align: left;
      thead {
        tr {
          background-color: #dbe2ef;
          border-radius: 4px;
          th {
            padding: 20px 10px;
            @include Roboto;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      tbody {
        tr {
          background-color: #fff;
          border-radius: 4px;
          cursor: pointer;
          td {
            @include Gilroy-Regular;
            font-weight: 400;
            font-size: 14px;
            text-transform: capitalize;
            padding: 20px 10px;
            &.file {
              p {
                color: #6c7ae0;
              }
            }
          }
        }
      }
    }
  }
  // .new_table {
  //   width: 100%;
  //   .table-body {
  //     width: 100%;
  //     display: table;
  //     .row {
  //       width: 100%;
  //       display: flex;
  //       .cell {
  //       }
  //       &.header {
  //         color: #ffffff;
  //         background: #6c7ae0;
  //         .cell {
  //           font-family: Poppins-Regular;
  //           font-size: 18px;
  //           color: #fff;
  //           line-height: 1.2;
  //           font-weight: unset !important;
  //           padding-top: 19px;
  //           padding-bottom: 19px;
  //         }
  //       }
  //     }
  //   }
  // }
  .pagination {
    padding-top: 30px;
    display: flex;
    justify-content: right;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        @include Roboto;
        font-size: 12px;
        font-weight: bold;
        list-style: none;
        padding: 6px 10px;
        border: 1px solid #000;
        margin-right: 5px;
        cursor: pointer;
        &:hover {
          background-color: #fff;
          border: none;
          border: 1px solid #535353;
          color: #535353;
        }
        &.active {
          background-color: #fff;
          border: none;
          border: 1px solid #535353;
          color: #535353;
        }
      }
    }
  }
}
.loader {
  background: #edebeb;
  margin-top: -100px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
