@import '../../../../resources';
.corporateUser {
  display: flex;
  margin-top: -100px;
  position: relative;
  .left {
    width: 74%;
    padding: 100px 30px 50px 30px;
    height: 100vh;
    overflow-y: auto;
    @include mobile-800 {
      width: 100%;
      padding: 100px 20px 0 20px;
    }
    .top {
      display: flex;
      justify-content: right;
      @include mobile-800 {
        justify-content: space-between;
        align-items: center;
      }
      .burger {
        display: none;
        @include mobile-800 {
          display: block;
        }
        svg {
          font-size: 30px;
        }
      }
    }
    .back {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 35px;
      flex-wrap: wrap;
      .back_left {
        display: flex;
        align-items: center;
        @include mobile-800 {
          padding-bottom: 20px;
        }
        h1 {
          @include Gilroy-Bold;
          font-size: 25px;
          margin-left: 15px;
        }
        svg {
          cursor: pointer;
          font-size: 20px;
        }
      }
      .back_right {
        p {
          font-size: 16px;
          @include Gilroy-Regular;
        }
      }
    }
    .user_details {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      flex-wrap: wrap;
      border-bottom: 0.5px solid #a2a2a2;
      padding-bottom: 50px;
      margin-bottom: 30px;

      .user_left {
        width: 25%;
        position: relative;
        @include mobile-600 {
          width: 100%;
          margin-bottom: 20px;
        }
        .status {
          position: absolute;
          width: 100%;
          text-align: right;
          display: flex;
          justify-content: right;
          padding: 10px;
          div {
            background: #39bb46;
            border-radius: 3px;
            padding: 5px 10px;
            width: fit-content;
            p {
              @include Gilroy-Regular;
              font-size: 12px;
              color: #fff;
            }
          }
        }
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          object-position: center;
          border-radius: 7px;
        }
      }
      .user_right {
        width: 72%;
        background-color: #fff;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-radius: 5px;
        @include mobile-600 {
          width: 100%;
        }
        .card {
          width: 47%;
          margin-bottom: 20px;
          @include mobile-600 {
            width: 100%;
          }
          p {
            @include Gilroy-Regular;
            font-size: 13px;
            padding-bottom: 7px;
          }
          h2 {
            @include Gilroy-Bold;
            font-size: 18px;
            word-break: break-all;
          }
        }
      }
    }
    .information_page {
      padding-bottom: 50px;
      .info {
        .info-head {
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          justify-content: space-between;
          flex-wrap: wrap;
          button {
            margin-left: 20px;
            @include Gilroy-Regular;
            font-size: 14px;
            border: none;
            background: none;
            &.save {
              padding: 8px 16px;
              background: #ff8a00;
              border-radius: 21px;
              color: #fff;
            }
          }
        }
        .info_top {
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          &.dif {
            padding-bottom: 0;
          }
          .icon {
            padding-right: 15px;
            svg {
              font-size: 20px;
            }
          }
          h2 {
            font-size: 20px;
            @include Gilroy-Bold;
          }
          .info-top_wrap {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            .one {
              display: flex;
              align-items: center;
              .icon {
                padding-right: 15px;
                svg {
                  font-size: 20px;
                }
              }
              h2 {
                font-size: 20px;
                @include Gilroy-Bold;
              }
            }
            .two {
              button {
                @include Gilroy-Regular;
                font-size: 12px;
                border: none;
                padding: 10px 20px;
                color: #000;
                border: 1px solid #afafaf;
                border-radius: 4px;
                margin-left: 10px;
                background: #f1f5fd;
                &.active {
                  color: #fff;
                  background: #ff8a00;
                  border: none;
                }
              }
            }
          }
        }
        .info_wrap {
          .four_wrap {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .card {
              padding-bottom: 25px;
              padding-right: 20px;
              @include mobile-420 {
                width: 100%;
                padding-right: 0;
              }
              p {
                @include Gilroy-Regular;
                font-size: 13px;
                padding-bottom: 7px;
              }
              h2 {
                @include Gilroy-Bold;
                font-size: 18px;
              }
            }
          }
          .single_wrap {
            width: 100%;
            padding-bottom: 25px;
            p {
              @include Gilroy-Regular;
              font-size: 13px;
              padding-bottom: 7px;
            }
            h2 {
              @include Gilroy-Bold;
              font-size: 18px;
            }
          }
          .cover_wrap {
            .card {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: #fff;
              padding: 15px 25px;
              border-radius: 5px;
              margin-bottom: 30px;
              .card_left {
                display: flex;
                align-items: center;
                .one {
                  padding-right: 13px;
                }
                .two {
                  h2 {
                    @include Gilroy-Bold;
                    font-size: 18px;
                    padding-bottom: 5px;
                  }
                  p {
                    @include Gilroy-Regular;
                    font-size: 14px;
                  }
                }
              }
              .card_right {
                svg {
                  font-size: 25px;
                  color: #848484;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .info-table {
          table {
            width: 100%;
            thead {
              tr {
                th {
                  @include Gilroy-Medium;
                  font-size: 16px;
                  padding: 20px 10px;
                  background-color: #fff;
                }
              }
            }
            tbody {
              tr {
                td {
                  @include Gilroy-Medium;
                  font-size: 14px;
                  padding: 10px 10px;
                  background-color: #fff;
                  &.count {
                    text-align: right;
                  }
                  button {
                    @include Gilroy-Regular;
                    font-size: 12px;
                    border: none;
                    padding: 10px 20px;
                    color: #000;
                    border: 1px solid #afafaf;
                    border-radius: 4px;
                    margin-left: 10px;
                    background: #f1f5fd;
                  }
                }
                &:nth-child(odd) {
                  td {
                    background-color: #f1f6ff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .right {
    width: 25%;
    background-color: #fff;
    height: 100vh;
    padding: 100px 20px 0px 20px;
    z-index: 1;
    @include mobile-800 {
      position: absolute;
      width: 35%;
      right: 100%;
      &.active {
        right: 0;
      }
    }
    @include mobile-600 {
      width: 60%;
    }
    @include mobile-420 {
      width: 80%;
    }
    .close {
      display: none;
      @include mobile-800 {
        display: block;
      }
      svg {
        font-size: 30px;
      }
    }
    .top {
      display: flex;
      justify-content: right;
      button {
        @include Gilroy-Regular;
        font-size: 14px;
        border: none;
        padding: 10px 30px;
        background: #ff8a00;
        border: 1px solid #ff8a00;
        border-radius: 21px;
        color: #fff;
        display: flex;
        p {
          padding-right: 5px;
        }
      }
    }
    .tag {
      padding-top: 35px;
      padding-bottom: 30px;
      h1 {
        @include Gilroy-Bold;
        font-size: 25px;
        @include mobile-420 {
          font-size: 20px;
        }
      }
    }
    .links {
      ul {
        background: #e6ecf8;
        border: 1px solid #dae3f6;
        border-radius: 10px;
        list-style: none;
        li {
          padding: 20px;
          font-size: 16px;
          @include Gilroy-Regular;
          border-bottom: 1px solid #dae3f6;
          cursor: pointer;
          &:nth-child(1) {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          &:nth-child(6) {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          &.active {
            background: #ff8a00;
            color: #fff;
          }
        }
      }
    }
  }
}
.vError {
  color: red;
}
